<template>
  <div class="deposit-and-stability-section">
    <div class="container">
      <div class="row deposit-row">
        <div class="col-12 col-md-6 content-col">
          <h1 class="title headline-l">
            {{ $t("bnoScreen.goldDeposit.title") }}
          </h1>
          <h4 class="subtitle">{{ $t("bnoScreen.goldDeposit.subtitle") }}</h4>
          <div
            class="content"
            v-html="$t('bnoScreen.goldDeposit.description')"
          />
          <span class="note" v-html="$t('bnoScreen.goldDeposit.note')" />
          <span class="note2" v-html="$t('bnoScreen.goldDeposit.note2')" />
        </div>
        <div class="d-none d-md-block col-md-1"></div>
        <div class="col-12 col-md-5 illustration-col">
          <img class="illustration" src="/images/sections/bno/aranyletet.png" />
        </div>
      </div>
      <div class="row stability-row flex-md-row-reverse">
        <div class="col-12 col-md-6 content-col">
          <h1 class="title headline-l">
            {{ $t("bnoScreen.stability.title") }}
          </h1>
          <h4 class="subtitle">{{ $t("bnoScreen.stability.subtitle") }}</h4>
          <div class="content" v-html="$t('bnoScreen.stability.description')" />
        </div>
        <div class="d-none d-md-block col-md-1"></div>
        <div class="col-12 col-md-5 illustration-col">
          <img class="illustration" src="/images/sections/bno/stabilitas.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DepositAndStabilitySection",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>
 
<style lang='scss' scoped>
.deposit-and-stability-section {
  padding: 90px 0 80px;
  border-bottom: 1px solid $blockstock-yellow;

  @media (max-width: $breakpoint-sm) {
    padding: 40px 0 50px;
  }
}

.row {
  @media (max-width: $breakpoint-sm) {
    display: flex;
    flex-wrap: wrap;

    .illustration-col {
      order: 1;
      margin: 0 0 65px;
      padding: 0px;
    }

    .content-col {
      order: 2;
    }
  }
}

.deposit-row {
  padding: 0 0 85px;

  @media (max-width: $breakpoint-sm) {
    padding: 0 0 65px;
  }
}

.title {
  color: $blockstock-yellow;
  margin: 0 0 20px;

  @media (max-width: $breakpoint-sm) {
    font-size: 38px;
  }
}

.stability-row {
  .illustration {
    margin: 0 auto;
  }
}

.subtitle {
  color: $dark-grey;
  font-size: 24px;
  line-height: 35px;
  margin: 0 0 40px;
  font-family: "HKNova-Bold", sans-serif;
}

.content {
  &::v-deep {
    p {
      margin: 0 0 30px;
      line-height: 30px;

      &:last-child {
        margin: 0;
      }
    }

    sup {
      font-size: 18px;
      top: 2px;
    }
  }
}

.note {
  font-size: 12px;
  line-height: 24px;
  margin: 20px 0 0;
  display: block;

  &::v-deep {
    sup {
      font-size: 14px;
      top: 2px;
    }
  }
}

.note2 {
  font-size: 12px;
  line-height: 24px;
  display: block;

  &::v-deep {
    sup {
      font-size: 14px;
      top: 2px;
    }
  }
}

.illustration {
  margin: 70px auto 0;

  @media (max-width: $breakpoint-sm) {
    margin: 0 auto;
  }
}
</style>