<template>
  <div class="details-section">
    <div class="container">
      <div class="row operation">
        <div class="col-12 col-md-6 content-col">
          <h1 class="title headline-l">{{ $t('bnoScreen.operation.title') }}</h1>
          <h4 class="subtitle">{{ $t('bnoScreen.operation.subtitle') }}</h4>
          <div class="content" v-html="$t('bnoScreen.operation.description')" />
        </div>
        <div class="col-12 col-md-6 illustration-col">
          <img :src="`/images/sections/bno/hogyan_${$i18n.locale}.svg`" class="illustration">
        </div>
      </div>
      <div class="row purchase-row">
        <div class="col-12">
          <h4 class="title">
            {{ $t("bnoScreen.bnoTrade.limits.title") }}
          </h4>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <bordered-box
            class="box-description"
            :title="
              $t('bnoScreen.bnoTrade.limits.blocks.purchase.title')
            "
            :borderColor="'#FCBB11'"
            :boxShadow="'0px 4px 8px rgba(75, 46, 170, 0.1)'"
            :background="boxOptions.background"
          >
            <template v-slot:description>
              <ul class="list">
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.purchase.firstBuy"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.purchase.min"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.purchase.max"
                    )
                  }}
                </li>
              </ul>
            </template>
          </bordered-box>
        </div>
        <div class="col-12 col-lg-6 mb-3 pt-md-4 pt-4 pt-lg-0">
          <bordered-box
            class="box-description"
            :title="
              $t('bnoScreen.bnoTrade.limits.blocks.sell.title')
            "
            :borderColor="'#FCBB11'"
            :boxShadow="'0px 4px 8px rgba(75, 46, 170, 0.1)'"
            :background="boxOptions.background"
          >
            <template v-slot:description>
              <ul class="list">
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.sell.min"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.sell.or"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.sell.max"
                    )
                  }}
                </li>
              </ul>
            </template>
          </bordered-box>
        </div>
        <div class="col-12 col-lg-6 mb-3 pt-md-4 pt-4 pt-lg-0 mt-2">
          <bordered-box
            class="box-description"
            :title="
              $t('bnoScreen.bnoTrade.limits.blocks.transfer.title')
            "
            :borderColor="'#FCBB11'"
            :boxShadow="'0px 4px 8px rgba(75, 46, 170, 0.1)'"
            :background="boxOptions.background"
          >
            <template v-slot:description>
              <ul class="list">
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.transfer.min"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.transfer.or"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoScreen.bnoTrade.limits.blocks.transfer.max"
                    )
                  }}
                </li>
              </ul>
            </template>
          </bordered-box>
        </div>
        <div class="note-row">
          <ul class="list note">
            <li>
              {{
                $t(
                  "bnoScreen.bnoTrade.limits.note.min"
                )
              }}
            </li>
            <li class="list-2">
              {{
                $t(
                  "bnoScreen.bnoTrade.limits.note.max"
                )
              }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row stores mx-0 d-block">
        <h1 class="title">{{ $t('bnoScreen.stores.label') }}</h1>
        <div class="stores-list">
          <store-button version="apple" />
          <store-button version="google" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BorderedBox from '@/components/elements/BorderedBox';


export default {
  name: "DetailsSection",
  props: {
    boxOptions: {
      type: Object,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#F6F4FF"
    },
  },
  components: {
    BorderedBox
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.details-section {
  padding: 90px 0 110px;
  border-bottom: 1px solid $blockstock-yellow;

  @media (max-width: $breakpoint-sm) {
    padding: 50px 0 85px;
  }
}

.title {
  color: $blockstock-yellow;
  margin: 0 0 45px;
}

.subtitle {
  color: $dark-grey;
  font-size: 24px;
  line-height: 35px;
  margin: 0 0 40px;
  font-family: "HKNova-Bold", sans-serif;
}

.row {
  @media (max-width: $breakpoint-sm) {
    display: flex;
    flex-wrap: wrap;

    .illustration-col {
      order: 1;
      margin: 0 0 65px;
    }

    .content-col {
      order: 2;
    }
  }
}

.content {
  &::v-deep {
    p {
      margin: 0 0 30px;
      line-height: 30px;

      &:last-child {
        margin: 0;
      }
    }

    b {
      color: red;
      font-weight: 400;
    }

    sup {
      font-size: 18px;
      top: 2px;
    }
  }
}

.row.operation {
  padding: 0 0 180px;

  @media (max-width: $breakpoint-sm) {
    padding: 0 0 55px;
  }
}

.illustration {
  margin: 70px auto 0;

  @media (max-width: $breakpoint-sm) {
    margin: 0 auto;
  }
}

.row.stores {
  padding-top: 85px;

  @media (max-width: $breakpoint-sm) {
    padding-top: 35px;
  }

  .title {
    text-align: center;
    color: $dark-grey;
    font-family: "HKNova-Bold", sans-serif;
    font-size: 24px;
    line-height: 35px;
  }
}

.bordered-box {
  height: 100%;
}

.stores-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;

  @media (max-width: $breakpoint-sm) {
    column-gap: 20px;
  }

  .store-button {
    display: inline-block;
    width: auto;

    &.google-button {
      margin-right: auto;

      @media (max-width: $breakpoint-sm) {
        margin-right: 0;
      }

      &::v-deep .button-text {
        @media (max-width: $breakpoint-sm) {
          margin: 0 auto;
        }
      }
    }

    &.apple-button {
      margin-left: auto;

      @media (max-width: $breakpoint-sm) {
        margin-left: 0;
      }

      &::v-deep .button-text {
        @media (max-width: $breakpoint-sm) {
          margin: 0 auto;
        }
      }
    }
  }
}
.purchase-row{
  .title{
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
  }
  .box-description{
    color: #1B1B1B;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }
  .list{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      color:#1B1B1B;
    }
  }
}
.note-row{
    max-width: 796px;
    margin: 20px 0;
    .list{
      list-style-type: "*";
    }
    .list-2{
      list-style-type: "**";
    }
    li{
      padding-left: 15px;
      padding-top: 10px;
    }
    .note {
      font-size: 16px;
      line-height: 24px;
      color: #1B1B1B;
      padding: 0 25px;
    }
}
</style>