<template>
  <div class="whitepaper-section">
    <div class="container">
      <h1 class="title headline-l">{{ $t("bnoScreen.whitepaper.title") }}</h1>
      <h4 class="subtitle">{{ $t("bnoScreen.whitepaper.subtitle") }}</h4>
      <div class="content">{{ $t("bnoScreen.whitepaper.body") }}</div>
      <div class="button-row">
        <base-button size="large" type="blockstock-yellow" :text="$t('bnoScreen.whitepaper.button')" @clicked="handleButtonClick()" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhitePaperSection",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      window.open("https://data.blockben.com/whitepaper/BlockNote_Whitepaper_EN.pdf", "_blank");
    }
  }
};
</script>
 
<style lang='scss' scoped>
.whitepaper-section {
  border-bottom: 1px solid $ebso-section-border-color;
}
.title {
  color: $blockstock-yellow;
  margin: 40px 0 20px;
  text-align: center;

  @media (max-width: $breakpoint-sm) {
    font-size: 38px;
  }
}
.subtitle {
  color: #1B1B1B;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  margin: 40px 0 40px;
  font-family: "HKNova-Bold", sans-serif;
}
.content {
  color: #1B1B1B;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  margin: 0 0 40px;
  font-family: "HKNova", sans-serif;
}
.button-row {
  text-align: center;
  margin: 60px 0 60px;
}
</style>