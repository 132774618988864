<template>
  <div id="bno-screen">
    <jumbotron
      :logoSrc="'/images/logos/bno-yellow-logo.svg'"
      :title="$t('bnoScreen.welcome.title')"
      :description="$t('bnoScreen.welcome.description')"
      :background="'linear-gradient(101.23deg, #FCBC0D -2.31%, #F39665 104.94%)'"
      :backgroundImage="'/images/sections/bno/desktop/header_background.jpg'"
      :mobileBackgroundImage="'/images/sections/bno/mobile/header_background.jpg'"
      :titleColor="'#FCBB11'"
      :textColor="'#FCBB11'"
      :storesLabel="$t('bnoScreen.welcome.storesLabel')"
    >
      <template v-slot:chart>
        <chart-component 
          v-if="refactoredData"
          :titleText="$t('bnoScreen.welcome.chart.title')"
          :subtitleText="$t('bnoScreen.welcome.chart.subtitle', { value: calculatedLastPrice })"
          :infoText="$t('bnoScreen.welcome.chart.infoText')"
          :chart-data="refactoredData"
          :gradientStartColor="'rgba(245, 154, 20, 0.4)'"
          :gradientEndColor="'rgba(245, 154, 20, 0)'"
          :infoTooltipBackground="'linear-gradient(101.23deg, #FCBC0D -2.31%, #F39665 104.94%)'"
          :labelsColor="'#1B1B1B'"
        />
      </template>
    </jumbotron>
    <quote
      :quote="$t('bnoScreen.quote.content')"
      :author="$t('bnoScreen.quote.author')"
    />
    <deposit-and-stability-section />
    <details-section :box-options="{'background': 'linear-gradient(111.11deg, #FFFFFF -3.09%, #FEFEFE 53.29%, #F7F7F7 103.03%)' }" />
    <warning-section :backgroundColor="'#fff'" />
    <white-paper-section />
    <other-products
      :hiddenProductKey="'bno'"
      :cardBorderColor="'#E8A83B'"
      :cardShadow="'0px 4px 8px rgba(75, 46, 170, 0.1)'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "@/api";
import moment from 'moment';
import Quote from "@/components/sections/Quote";
import DepositAndStabilitySection from "@/components/sections/bno/DepositAndStabilitySection";
import DetailsSection from "@/components/sections/bno/DetailsSection";
import OtherProducts from "@/components/sections/OtherProducts";
import ChartComponent from "@/components/elements/ChartComponent";
import WarningSection from "@/components/sections/bit/WarningSection";
import WhitePaperSection from "@/components/sections/bno/WhitePaperSection.vue";

export default {
  name: "BnoScreen",
  props: {},
  components: {
    WhitePaperSection,
    Quote,
    DepositAndStabilitySection,
    DetailsSection,
    OtherProducts,
    ChartComponent,
    WarningSection,
  },
  data: () => ({
    chartDataLimit: 7,
    chartDataInterval: '1m',
    chartData: undefined,
    ctx: undefined
  }),
  created() {},
  computed: {
    ...mapGetters({
      getSelectedLanguage: "settings/getSelectedLanguage",
    }),
    calculatedLastPrice() {
      if (this.chartData) {
        return (this.chartData[this.chartData.length - 1].rate).toFixed(2);
      }
      return undefined;
    },
    refactoredData() {
      if (this.chartData) {
        return {
          labels: this.chartData.map((obj) => {
            moment.locale(this.getSelectedLanguage);
            const newDate = moment(new Date(obj.date)).format('MMM').replace('.', '');
            return newDate.toLowerCase();
          }),
          tooltipLabel: (value) => `€${value.yLabel.toFixed(2)} / 100 BNO`,
          datasets: [
            {
              data: this.chartData.map((obj) => obj.rate),
              borderColor: '#F59A14',
              pointBackgroundColor: 'rgba(0, 0, 0, 0)',
              pointBorderColor: 'rgba(0, 0, 0, 0)',
              borderCapStyle: 'round',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: '#4B2EAA',
              pointHighlightFill: "#fff",
              pointHoverBorderWidth: 2,
              backgroundColor: "transparent",
              pointColor : "#fff",
              pointStrokeColor : "#ff6c23",
            },
          ],
        };
      }
      return undefined;
    }
  },
  methods: {},
  async mounted() {
    const response = await Api.get(`publicReferencePrice/BNO?limit=${this.chartDataLimit}&interval=${this.chartDataInterval}`);
    this.chartData = await response.json();

    const res = await Api.get("publicPrice");
    this.currentPrice = await res.json();
    
    let currentProduct = this.currentPrice.find(price => {
      return price.asset === "BNO" && price.type === "Buy";
    });

    if(currentProduct){
      this.chartData[this.chartData.length - 1].rate = currentProduct.rate;
    }
  },
};
</script>
 
<style lang='scss' scoped>
.jumbotron {
  &::v-deep {
    .logo {
      margin: 0 0 65px;
    }
  }
}

.warning-section {
  border-bottom: 1px solid $ebso-section-border-color;
  color: $black;

  &::v-deep .icon-wrapper {
    img {
      filter: invert(1);
    }
  }
}
</style>
